import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { frFR } from '@mui/x-date-pickers/locales';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Autocomplete, TextField, Button, Stack, Modal, Box, Alert, AlertTitle, IconButton } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as LogoAffichage } from '../../ecbar.svg';
import { formatDate } from '../../utils/utils.js';
import DownloadButton from './DownloadButton.js';
import SeeRecipe from '../SeeRecipe/SeeRecipe.js';
import FileUploadModal from './FileUploadModel.js';
import CircularProgressWithLabel from "./CircularWithValueLabel.js";
import { useData } from '../../DataProvider.js'
import 'dayjs/locale/fr'; // Import de la locale française

dayjs.locale('fr');

const styles = {
  day: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#175E8D'
  },
  input: {
    marginTop: 20,
    marginBottom: 20,
    justifyContent: 'center',
    width: "100%",
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    marginLeft: '20px' // Ajout de la marge gauche
  },
  buttonContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    marginTop: '20px'
  },
  generateButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '20px'
  },
  generateButton: {
    width: 250,
    height: 'auto',
    flexDirection: 'column',
    borderRadius: 10,
    bgcolor: 'success.main',
    '&:hover': { bgcolor: 'success.dark' }
  }
};

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const isSelected = !outsideCurrentMonth && highlightedDays.some(highlightedDay =>
    highlightedDay.isSame(day, 'day')
  );

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '✅' : '❓'}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

function MenuEntryPage() {
  // States
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [menu, setMenu] = useState([{ recette: null, portions: '' }]);
  const [displayData, setDisplayData] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [recettes, setRecettes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [totalCO2, setTotalCO2] = useState(0);
  const [averageCO2, setAverageCO2] = useState(0);

  const { restaurant, client, data: recettesDATA, loadDataForRestaurant, accessToken } = useData();  
  const requestAbortController = useRef(null);
  useEffect(() => {
    if (recettesDATA) {
      setRecettes(recettesDATA);
    }
  }, [recettesDATA]);

  // Effects
  useEffect(() => {
    const timer = setInterval(() => {
      if (isLoading) {
        setProgress((prev) => Math.min(95, prev + 5));
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [isLoading]);

  useEffect(() => {
    fetchDisplayData();
  }, [selectedDate, restaurant]);

  useEffect(() => {
    calculateCO2Impact();
  }, [menu]);

  // Handlers
  const fetchDisplayData = async () => {
    if (!restaurant) return;
    setCalendarLoading(true);
    try {
      const response = await fetch(
        `https://foodprint-api.azure-api.net/http_trigger_getDisplayDateGenerated/http_trigger_getDisplayDateGenerated?container=${restaurant}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch display data');

      const data = await response.json();
      const currentDateStr = selectedDate.format('DD-MM-YYYY');
      const currentDisplay = data.dates.find(d => d.date === currentDateStr);

      setDisplayData(currentDisplay?.urls || null);
      setIsEditing(!currentDisplay);

      const parsedDates = data.dates.map(d => dayjs(d.date, 'DD-MM-YYYY'));
      setHighlightedDays(parsedDates);
    } catch (err) {
      setError(err.message);
    } finally {
      setCalendarLoading(false);
    }
  };

  const handleAddRecipe = () => {
    setMenu([...menu, { recette: null, portions: '' }]);
  };

  const handleRecipeChange = (index, newRecipe) => {
    const newMenu = [...menu];
    newMenu[index] = { ...newMenu[index], recette: newRecipe };
    setMenu(newMenu);
  };

  const handlePortionsChange = (index, portions) => {
    const newMenu = [...menu];
    newMenu[index] = { ...newMenu[index], portions };
    setMenu(newMenu);
  };

  const calculateCO2Impact = () => {
    let total = 0;
    let count = 0;

    menu.forEach(item => {
      if (item.recette?.Footprint && item.portions) {
        const impact = (item.recette.Footprint / item.recette.Nb_portion) * parseInt(item.portions);
        total += impact;
        count += parseInt(item.portions);
      }
    });

    setTotalCO2(total / 1000);
    setAverageCO2(count > 0 ? total / 1000 / count : 0);
  };

  const handleGenerateDisplay = async () => {
    setIsLoading(true);
    setProgress(0);

    try {
      const recipes_id = menu.map(item => item.recette?.id).join(';');
      const portions = menu.map(item => item.portions).join(';');
      const formattedDate = selectedDate.format('DD-MM-YYYY');

      const response = await fetch(
        `https://foodprint-api.azure-api.net/foodprintFunctionsV3/http_trigger_displayGeneration?recipes_id=${recipes_id}&portions=${portions}&restaurant=${restaurant}&date=${formattedDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );

      if (!response.ok) throw new Error('Generation failed');

      const data = await response.json();
      setDisplayData({ pdf_url: data.url_pdf, jpeg_url: data.url_jpeg });
      setIsEditing(false);
      await loadDataForRestaurant(restaurant, client);
      await fetchDisplayData();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if (error) {
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', verticalAlign: 'center' }} >
          <Alert severity="error" sx={{ verticalAlign: 'center' }}>
            <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br />
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
          </Alert>
        </Box>
      </div>
    )
  }
  return (
    <div className='menuentry'>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{ marginLeft: 10 }}>Saisie du menu</h1>
      </Box>

      <FileUploadModal onUploadComplete={fetchDisplayData}/>

      <Stack spacing={2} direction="row" style={styles.input}>
        <div style={styles.calendarContainer}>
          <h1 style={styles.day}>Selectionner le jour à saisir</h1>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
            adapterLocale="fr"
          >
            <DateCalendar
              value={selectedDate}
              loading={calendarLoading}
              onMonthChange={fetchDisplayData}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{ day: ServerDay }}
              slotProps={{ day: { highlightedDays } }}
              onChange={setSelectedDate}
              sx={{
                border: '1px solid #ccc',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
                fontFamily: "Alegreya Sans",
              }}
              views={['year', 'month', 'day']}
              showDaysOutsideCurrentMonth
            />
          </LocalizationProvider>

          {!isEditing && displayData && (
            <div style={styles.buttonContainer}>
              <Button
                variant="contained"
                onClick={() => setIsEditing(true)}
                sx={{
                  width: 250,
                  height: 'auto',
                  flexDirection: 'column',
                  borderRadius: 10,
                  bgcolor: '#175E8D',
                  '&:hover': { bgcolor: '#134a6d' }
                }}
              >
                Éditer le menu
                <LogoAffichage style={{ width: '100%', height: 'auto' }} />
              </Button>

              <DownloadButton
                dateFormat={selectedDate.format("DD-MM-YYYY")}
                urls={{
                  pdf: displayData.pdf_url,
                  jpeg: displayData.jpeg_url
                }}
              />
            </div>
          )}
        </div>

        <div>
          <h1 style={styles.day}>Saisie du {formatDate(selectedDate)}</h1>

          {!isEditing && displayData ? (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <img
                src={displayData.jpeg_url}
                alt="Menu affiché"
                style={{
                  maxWidth: '80%',
                  height: 'auto',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  border: '2px solid #175E8D'
                }}
              />
            </Box>
          ) : (
            <>
              <Stack spacing={2} direction="column">
                {menu.map((item, index) => (
                  <Stack key={index} direction="row" spacing={1} alignItems="center">
                    <Autocomplete
                      options={recettesDATA || []}
                      getOptionLabel={(option) => option.Name || ''}
                      value={item.recette}
                      onChange={(_, value) => handleRecipeChange(index, value)}
                      onInputChange={(_, value) => {
                        if (value.length > 1) {
                          const filtered = (recettesDATA || []).filter(r => 
                            r.Name.toLowerCase().includes(value.toLowerCase())
                          );
                          setRecettes(filtered);
                        }
                      }}
                      sx={{ width: 450 }}
                      renderInput={(params) => (
                        <TextField {...params} label={`Recette ${index + 1}`} />
                      )}
                    />
                    <TextField
                      label="Portions"
                      type="number"
                      value={item.portions}
                      onChange={(e) => handlePortionsChange(index, e.target.value)}
                      sx={{ width: 250 }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedRecipe(item.recette);
                        setModalOpen(true);
                      }}
                      disabled={!item.recette}
                    >
                      Voir
                    </Button>
                  </Stack>
                ))}
                <Button
                  variant="outlined"
                  onClick={handleAddRecipe}
                >
                  Ajouter une recette à afficher
                </Button>
              </Stack>
              
              {isEditing && (
                <div style={styles.generateButtonContainer}>
                  <Button
                    variant="contained"
                    onClick={handleGenerateDisplay}
                    disabled={!menu.every(item => item.recette && item.portions)}
                    sx={styles.generateButton}
                  >
                    Générer l'affichage
                    {isLoading && <CircularProgressWithLabel value={progress} />}
                    <LogoAffichage style={{ width: '100%', height: 'auto' }} />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </Stack>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          <AlertTitle>Erreur</AlertTitle>
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
        <Alert severity="info" sx={{ width: 'fit-content' }}>
          <AlertTitle>Impact CO₂</AlertTitle>
          <p>Total CO₂ produit pour ce menu: {totalCO2.toFixed(2)} kg</p>
          <p>Moyenne CO₂ par portion: {averageCO2.toFixed(2)} kg</p>
        </Alert>
      </Box>

      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto"
        }}>
          <IconButton
            onClick={() => setModalOpen(false)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedRecipe && <SeeRecipe p_recipe={selectedRecipe} />}
        </Box>
      </Modal>
    </div>
  );
}

export default MenuEntryPage;