import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Alert,
  CircularProgress,
  Collapse,
  Stepper,
  Step,
  StepLabel,
  LinearProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useData } from '../../DataProvider.js';

const POLLING_INTERVAL = 10000; // 10 seconds
const MAX_RETRIES = 6;

const FileUploadModal = ({ onUploadComplete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('idle');
  const [initialDatesCount, setInitialDatesCount] = useState(0);
  const [currentDatesCount, setCurrentDatesCount] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const { accessToken, restaurant, emailAdress, loadDataForRestaurant } = useData();

  const steps = [
    'Téléversement du fichier',
    'Traitement des données',
    'Génération des affichages',
    'Finalisation'
  ];

  const getActiveStep = () => {
    switch (processingStatus) {
      case 'uploading':
        return 0;
      case 'processing':
        return 1;
      case 'generating':
        return 2;
      case 'completed':
        return 3;
      default:
        return 0;
    }
  };

  const finishProcessing = async () => {
    setProcessingStatus('completed');
    await loadDataForRestaurant(restaurant);
    if (onUploadComplete) {
      onUploadComplete();
    }
  };

  const checkProgress = useCallback(async () => {
    try {
      const response = await fetch(
        `https://foodprint-api.azure-api.net/http_trigger_getDisplayDateGenerated/http_trigger_getDisplayDateGenerated?container=${restaurant}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );

      if (!response.ok) throw new Error('Failed to check progress');
      
      const data = await response.json();
      const currentCount = data.dates?.length || 0;
      setCurrentDatesCount(currentCount);
      
      if (currentCount > initialDatesCount) {
        await finishProcessing();
        return true;
      }

      setRetryCount(prev => {
        if (prev >= MAX_RETRIES - 1) {
          finishProcessing();
          return prev;
        }
        return prev + 1;
      });
      
      return false;
    } catch (err) {
      setError("Erreur lors de la vérification de la progression");
      setRetryCount(prev => {
        if (prev >= MAX_RETRIES - 1) {
          finishProcessing();
          return prev;
        }
        return prev + 1;
      });
      return false;
    }
  }, [accessToken, restaurant, initialDatesCount, loadDataForRestaurant, onUploadComplete]);

  useEffect(() => {
    let intervalId;
    
    if (processingStatus === 'processing' && retryCount < MAX_RETRIES) {
      intervalId = setInterval(async () => {
        const shouldStop = await checkProgress();
        if (shouldStop || retryCount >= MAX_RETRIES - 1) {
          clearInterval(intervalId);
        }
      }, POLLING_INTERVAL);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [processingStatus, checkProgress, retryCount]);

  const handleClose = () => {
    if (!uploading) {
      setIsOpen(false);
      setSuccess(false);
      setFile(null);
      setError('');
      setProcessingStatus('idle');
      setRetryCount(0);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!selectedFile.name.match(/\.(xlsx|xls|ods)$/)) {
        setError('Veuillez sélectionner un fichier Excel valide (.xlsx ou .xls ou .ods)');
        return;
      }
      setFile(selectedFile);
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Veuillez sélectionner un fichier');
      return;
    }

    setUploading(true);
    setError('');
    setProcessingStatus('uploading');

    try {
      // Get initial dates count
      const initialCountResponse = await fetch(
        `https://foodprint-api.azure-api.net/http_trigger_getDisplayDateGenerated/http_trigger_getDisplayDateGenerated?container=${restaurant}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
      const initialData = await initialCountResponse.json();
      setInitialDatesCount(initialData.dates?.length || 0);

      const formData = new FormData();
      formData.append('menuFile', file);
      formData.append('file_name', file.name);
      formData.append('restaurant', restaurant);
      formData.append("emailAdress", emailAdress);

      const response = await fetch('https://foodprint-api.azure-api.net/uploadmenu/http_trigger_uploadMenu', {
        method: 'POST',
        body: formData,
        headers: {
          'Ocp-Apim-Subscription-Key': 'a9ab279af7544e16a0ee106909e1074f',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors du téléversement');
      }

      setProcessingStatus('processing');
    } catch (err) {
      setError("Une erreur s'est produite lors du téléversement");
      setProcessingStatus('idle');
    } finally {
      setUploading(false);
    }
  };

  const getStatusMessage = () => {
    switch (processingStatus) {
      case 'uploading':
        return "Téléversement du fichier en cours...";
      case 'processing':
        return "Traitement des données en cours...";
      case 'generating':
        return "Génération des affichages...";
      case 'completed':
        return "Traitement terminé avec succès !";
      default:
        return "";
    }
  };

  return (
    <>
      <Button 
        variant="contained" 
        onClick={() => setIsOpen(true)}
        startIcon={<CloudUploadIcon />}
      >
        Déposer votre menu pour générer l'affichage automatiquement
      </Button>

      <Dialog 
        open={isOpen} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Déposer votre menu pour générer l'affichage automatiquement
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Collapse in={processingStatus === 'idle'}>
              <Box
                sx={{
                  border: '2px dashed #ccc',
                  borderRadius: 2,
                  p: 3,
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  }
                }}
                component="label"
              >
                <input
                  type="file"
                  hidden
                  accept=".xlsx,.xls,.ods"
                  onChange={handleFileChange}
                />
                <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                <Typography variant="body1" gutterBottom>
                  Cliquez pour déposer votre fichier ou glissez-déposez
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  .XLSX, .XLS, .ODS
                </Typography>
              </Box>

              {file && (
                <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                  Fichier sélectionné: {file.name}
                </Typography>
              )}
            </Collapse>

            {processingStatus !== 'idle' && (
              <Box sx={{ mt: 4 }}>
                <Stepper activeStep={getActiveStep()} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    {getStatusMessage()}
                  </Typography>
                  
                  {processingStatus === 'processing' && (
                    <Box sx={{ mt: 2 }}>
                      <LinearProgress />
                      <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                        {/* Tentative {retryCount + 1}/{MAX_RETRIES} */}
                      </Typography>
                    </Box>
                  )}

                  {processingStatus === 'completed' && (
                    <Box sx={{ mt: 2 }}>
                      <CheckCircleOutlineIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
                      <Alert severity="success">
                        Les affichages ont été générés avec succès ! Vous pouvez maintenant les consulter dans le calendrier.
                      </Alert>
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          {processingStatus === 'idle' ? (
            <>
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={uploading}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                onClick={handleUpload}
                disabled={!file || uploading}
                startIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
              >
                {uploading ? 'Téléversement...' : 'Téléverser'}
              </Button>
            </>
          ) : processingStatus === 'completed' ? (
            <Button
              variant="contained"
              onClick={handleClose}
              color="success"
            >
              Fermer
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={true}
            >
              Traitement en cours...
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploadModal;