import React from 'react';
import './seerecipe.css';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import IngredientsList from './IngredientsList';
import NutritionalValues from './NutritionalValues';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useData } from '../../DataProvider';
import { generateReport } from '../../utils/utils';
import CircularProgress from '@mui/material/CircularProgress';

const getColor = (value) => {
  if (value < 500) return "#38A48E";
  if (value < 1000) return "#FFD700";
  if (value < 1500) return "#FFA500";
  if (value < 3000) return "#FF6347";
  return "#FF6347";
};

const textStyle = {
  textAlign: "center",
  margin: 0,
  padding: 0,
  lineHeight: "1",
};

const SeeRecipe = ({ p_recipe }) => {
  let loc = useLocation()
  if (loc.state) {
    console.log("mode page")
    const className = "seerecipes";
    const newStyles = `.${className} {
      margin-left: 17%;
      margin-right: 2%;
    }`;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = newStyles;
    document.head.appendChild(styleSheet);
  }
  let recipe = loc.state ? loc.state.recipe : p_recipe
  console.log("loc state", loc.state)

  const { restaurant, accessToken } = useData();
  const [loadingExport, setLoadingExport] = React.useState(false);
  const recipe_footprint = (recipe.Footprint / recipe.Nb_portion).toFixed(0);

  const handleExport = (event, index) => {
    setLoadingExport(true)
    generateReport(recipe.id, restaurant, accessToken).then(() => {
      setLoadingExport(false)
    })
  }

  return (
    <div className="seerecipes">
      <div style={{ textAlign: 'center' }}>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
          <img src="/logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>{recipe.Name}</h1>
          <Button 
            disabled={loadingExport} 
            onClick={(event) => handleExport(event, recipe.id)} 
            variant="contained" 
            style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius: 10, marginLeft: 10 }}
          >
            Exporter l'analyse
            {loadingExport && <CircularProgress />}
          </Button>
        </div>
        
        <h2 style={{ marginTop: -10, marginBottom: 30 }}>Pour {recipe.Nb_portion} personnes</h2>

        <div style={{ display: "flex", justifyContent: 'center', gap: '20px', marginBottom: 40, alignItems: 'center' }}>
          <img 
            src={`/nutriscore/${recipe.Nutriscore}.svg`}
            alt={recipe.Nutriscore}
            style={{ height: '40px' }}
          />
          <img 
            src={`/greenscore/${recipe.Ecoscore}.svg`}
            alt={recipe.Ecoscore}
            style={{ height: '50px' }}
          />
          <div style={{ textAlign: "center" }}>
            <h2 style={{ ...textStyle, color: getColor(recipe_footprint) }}>{recipe_footprint}</h2>
            <h4 style={textStyle}>gCO₂e/portion</h4>
          </div>
        </div>
      </div>

      <Box style={{ marginBottom: '2%' }}>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
          <img src="/logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>Informations nutritionnelles de la recette</h1>
        </div>
        <NutritionalValues recipe={recipe} />

        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: "3%" }}>
          <img src="/logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>Les ingrédients de la recette</h1>
        </div>
        <IngredientsList ingredients={recipe.Ingredients} />
      </Box>
    </div>
  );
};

export default SeeRecipe;