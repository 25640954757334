import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { 
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Fade,
  Paper
} from '@mui/material';
import { Login as LoginIcon, CalendarToday } from '@mui/icons-material';

const REDIRECT_URI = window.location.hostname === 'localhost' 
  ? 'http://localhost:3000' 
  : 'https://my.foodprint.eco/';

const CenteredLoginPage = () => {
  const { instance } = useMsal();
  const [idToken, setIdToken] = useState("");
  const [SignUpMode, setSignUpMode] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const generateCodeChallenge = async () => {
    const codeVerifier = Math.random().toString(36).substring(2);
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    const codeChallenge = btoa(String.fromCharCode(...new Uint8Array(hash)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
    return { codeVerifier, codeChallenge };
  };

  const handleCalendlyClick = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    window.open(`https://calendly.com/elisa-jourde-foodprint/30min?month=${formattedDate}`, "_blank");
  };

  const handleLogin = async () => {
    try {
      const { idToken } = await instance.loginPopup();
      setIdToken(idToken);
    } catch (error) {
      if (error.errorMessage?.includes("AADB2C90118")) {
        handlePasswordReset();
      }
    }
  };

  const handlePasswordReset = async () => {
    const { codeVerifier, codeChallenge } = await generateCodeChallenge();
    sessionStorage.setItem("code_verifier", codeVerifier);

    const passwordResetUrl = new URL(
      "https://foodprintfrance.b2clogin.com/foodprintfrance.onmicrosoft.com/oauth2/v2.0/authorize"
    );
    
    const params = {
      p: 'B2C_1_password_reset',
      client_id: 'e207f288-a006-44e1-8cc9-be4be0d2734d',
      response_type: 'code',
      redirect_uri: REDIRECT_URI,
      scope: 'openid',
      nonce: 'defaultNonce',
      prompt: 'login',
      code_challenge: codeChallenge,
      code_challenge_method: 'S256'
    };

    Object.entries(params).forEach(([key, value]) => 
      passwordResetUrl.searchParams.append(key, value)
    );

    window.location.href = passwordResetUrl.toString();
  };


  return (
    <Container 
      maxWidth={false} 
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
        py: 4
      }}
    >
      <Fade in timeout={800}>
        <Card 
          elevation={3} 
          sx={{
            maxWidth: 480,
            width: '100%',
            mx: 2,
            borderRadius: 4,
            bgcolor: 'white',
            overflow: 'visible',
            position: 'relative'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -40,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '180px',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'white',
              borderRadius: '20px 20px 0 0',
              boxShadow: '0 -4px 10px rgba(0,0,0,0.05)'
            }}
          >
            <img 
              src="logo_plateforme-cropped.svg" 
              alt="logo foodprint" 
              style={{ 
                maxWidth: '140px',
                maxHeight: '60px',
                objectFit: 'contain'
              }} 
            />
          </Box>

          <CardContent 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: 7,
              pb: 5,
              px: isMobile ? 3 : 5,
              gap: 1
            }}
          >
            <Typography 
              variant="h4" 
              sx={{
                fontFamily: 'Josefin Sans',
                color: '#2C3E50',
                textAlign: 'center',
                mb: 1,
                fontSize: isMobile ? '1.8rem' : '2.2rem'
              }}
            >
              Accèder à MyFoodPrint
            </Typography>

            <Button
              variant="contained"
              fullWidth
              onClick={handleLogin}
              startIcon={<LoginIcon />}
              sx={{
                bgcolor: '#37A18D',
                borderRadius: '12px',
                padding: '12px 24px',
                textTransform: 'none',
                fontFamily: 'Josefin Sans',
                fontSize: '1.1rem',
                boxShadow: '0 4px 12px rgba(55, 161, 141, 0.2)',
                '&:hover': {
                  bgcolor: '#2C8975',
                  boxShadow: '0 6px 16px rgba(55, 161, 141, 0.3)'
                }
              }}
            >
              Connexion
            </Button>

            <Box sx={{ 
              width: '100%', 
              textAlign: 'center',
              my: 1,
              position: 'relative'
            }}>
              <Typography 
                variant="h6" 
                sx={{
                  fontFamily: 'Josefin Sans',
                  color: '#2C3E50',
                  position: 'relative',
                  // '&::before, &::after': {
                  //   content: '""',
                  //   position: 'absolute',
                  //   top: '50%',
                  //   width: '60px',
                  //   height: '1px',
                  //   bgcolor: '#e0e0e0'
                  // },
                  // '&::before': {
                  //   left: isMobile ? '10%' : '20%'
                  // },
                  // '&::after': {
                  //   right: isMobile ? '10%' : '20%'
                  // }
                }}
              >
                Besoin d'en savoir plus ?
              </Typography>
            </Box>

            <Button
              variant="outlined"
              fullWidth
              onClick={handleCalendlyClick}
              startIcon={<CalendarToday />}
              sx={{
                borderColor: '#37A18D',
                color: '#37A18D',
                borderRadius: '12px',
                padding: '12px 24px',
                textTransform: 'none',
                fontFamily: 'Josefin Sans',
                fontSize: '1.1rem',
                borderWidth: '2px',
                '&:hover': {
                  borderColor: '#2C8975',
                  bgcolor: 'rgba(55, 161, 141, 0.05)',
                  borderWidth: '2px'
                }
              }}
            >
              Réserver une démonstration
            </Button>
          </CardContent>
        </Card>
      </Fade>
    </Container>
  );
};

export default CenteredLoginPage;