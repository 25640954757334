import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../DataProvider';
import { addrecipe, generateReport } from '../../utils/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import DuplicateIcon from '@mui/icons-material/ContentCopy';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

/*
This function fetch recipes from azure cosmos db in a container
*/
function createData(index, name, footprint, nutriscore, ecoscore, type, process_date) {
  //type maincourse, dessert, starter : translate to french
  if (type === "maincourse"|| type === "main course") {
    type = "plat principal";
  } else if (type === "dessert") {
    type = "dessert";
  } else if (type === "starter") {
    type = "entrée";
  }
  return {
    id: index,
    index,
    name,
    footprint,
    nutriscore,
    ecoscore,
    type,
    process_date,
  };
}

function descendingComparator(a, b, orderBy) {
  if (typeof b[orderBy] === 'number') {
    return b[orderBy] - a[orderBy];
  } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(b[orderBy])) {
    // Format DD/MM/YYYY : conversion en date pour comparer
    const [dayA, monthA, yearA] = a[orderBy].split('/').map(Number);
    const [dayB, monthB, yearB] = b[orderBy].split('/').map(Number);
    const dateA = new Date(yearA, monthA - 1, dayA);
    const dateB = new Date(yearB, monthB - 1, dayB);
    return dateB - dateA;
  } else {
    // Comparaison de chaînes pour l'ordre alphabétique
    return b[orderBy].localeCompare(a[orderBy]);
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nom de la recette',
  },
  {
    id: 'footprint',
    numeric: false,
    disablePadding: false,
    label: 'Impact carbone (gCO2e/portion)',
  },
  {
    id: 'nutriscore',
    numeric: false,
    disablePadding: false,
    label: 'Nutriscore',
  },
  {
    id: 'ecoscore',
    numeric: false,
    disablePadding: false,
    label: 'Green-Score',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type de recette',
  },
  {
    id: 'process_date',
    numeric: false,
    disablePadding: false,
    label: 'Date d\'analyse',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleSearchChange } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        justifyContent: 'center',
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <TextField
        label="Rechercher une recette"
        variant="outlined"
        onChange={handleSearchChange}
        sx={{ 
          marginTop: 3,
          width: '40%', // Définir la largeur ici, 2 fois plus longue que par défaut
          borderRadius: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
          },
        }}
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const { data: recettesDATA, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error, loadDataForRestaurant, accessToken } = useData();
  const [loadingExport, setLoadingExport] = React.useState([null, false]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [duplicateOpen, setDuplicateOpen] = React.useState(false);
  const [newRecipeName, setNewRecipeName] = React.useState('');
  const [processing, setProcessing] = React.useState(false);
  const [notification, setNotification] = React.useState({ open: false, message: '', severity: 'success' });
  const [duplicatedRecipe, setDuplicatedRecipe] = React.useState(null);

  let navigate = useNavigate();

  const handleActionDuplicate = (event, index) => {
    setDuplicatedRecipe(recettesDATA[index]);
    setNewRecipeName(`${recettesDATA[index].Name} (Copie)`);
    setDuplicateOpen(true);
  };
  
  const handleDuplicateConfirm = () => {
    setProcessing(true);
    const newRecipe = { ...duplicatedRecipe, Name: newRecipeName };
    addrecipe(restaurant, newRecipe, accessToken)
      .then((response) => {
        if (!response || response.error) {
          const errorMsg = response?.error?.includes("already exists") ? "Erreur: La recette existe déjà" : "Erreur lors de la duplication";
          setNotification({ open: true, message: errorMsg, severity: 'error' });
        } else {
          setNotification({ open: true, message: "Recette dupliquée avec succès", severity: 'success' });
          loadDataForRestaurant(restaurant, client);
        }
      })
      .catch(() => {
        setNotification({ open: true, message: "Erreur lors de la duplication", severity: 'error' });
      })
      .finally(() => {
        setProcessing(false);
        setDuplicateOpen(false);
      });
  };
  
  const handleDuplicateCancel = () => {
    setDuplicateOpen(false);
  };

  const visibleRows = React.useMemo(() => {
    if (!rows) {
      return [];
    }
    const filteredRows = rows.filter((row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [order, orderBy, page, rowsPerPage, rows, searchTerm]);

  const getDate = (timestamp) => {
    //Format DD/MM/YYYY 0 padding
    const date = new Date(timestamp*1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  React.useEffect(() => {
    if (recettesDATA) {
      const new_rows = recettesDATA.map((recipe, index) => createData(index, recipe.Name, parseInt(recipe.Footprint / recipe.Nb_portion), recipe.Nutriscore, recipe.Ecoscore, recipe.Type, getDate(recipe._ts)));
      setRows(new_rows);
      setOrderBy('name');
      setOrder('asc');
    }
  }, [recettesDATA]);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleActionDelete = (event, index) =>{
    // post on https://foodprint-api.azure-api.net/RemoveRecipe/http_trigger_removeRecipe, then reload restaurant
    let recipe_id = recettesDATA[index].id;
    // restaurant and recipe_id are the parameters
    fetch("https://foodprint-api.azure-api.net/RemoveRecipe/http_trigger_removeRecipe", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': 'a9ab279af7544e16a0ee106909e1074f',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({recipe_id: recipe_id, restaurant: restaurant})
    }).then(() => {
      loadDataForRestaurant(restaurant, client);
    }); 
    //Supprimer dans tous les cas de la vue, on part du principe qe ça foncitonne
    // timestamp to european date
    let new_recettes = recettesDATA.map((recipe, index) => createData(index, recipe.Name, parseInt(recipe.Footprint / recipe.Nb_portion), recipe.Nutriscore, recipe.Ecoscore, recipe.Type, getDate(recipe._ts)));
    new_recettes.splice(index, 1);
    setRows(new_recettes);

  }
  const handleActionSee = (event, index) => {
    navigate("/seerecipe/", { state: { recipe: recettesDATA[index] } });
  };

  const handleActionEdit = (event, index) => {
    navigate("/editrecipe/", { state: { recipe: recettesDATA[index] } });
  };

  const handleActionExport = (event, index) => {
    let recipe_id = recettesDATA[index].id;
    setLoadingExport([index, true]);
    generateReport(recipe_id, restaurant, accessToken).then(() => {
      setLoadingExport([index, false]);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} handleSearchChange={handleSearchChange} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={row.index}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.footprint}</TableCell>
                    <TableCell align="left">
                      <img src={`./nutriscore/${row.nutriscore}.svg`}
                        alt={row.nutriscore}
                        height="35%"
                        width="35%" 
                        style={{marginLeft:1, marginRight:1}}/>
                    </TableCell>
                    <TableCell align="left">
                      <img src={`./greenscore/${row.ecoscore}.svg`}
                        alt={row.ecoscore}
                        height="25%"
                        width="25%" />
                    </TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">
                      {row.process_date}
                    </TableCell>
                    <TableCell align="left">
                      <Button onClick={(event) => handleActionSee(event, row.index)} variant="contained" style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius: 10, marginTop: "2%" }}>Consulter</Button>
                      <Button onClick={(event) => handleActionEdit(event, row.index)} variant="contained" style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius: 10, marginLeft: "1%", marginTop: "2%" }}>Editer</Button>
                      <Button
                        onClick={(event) => handleActionDelete(event, row.index)}
                        variant="contained"
                        style={{ backgroundColor: '#F65238', color: '#FFFFFF', borderRadius: 10, minWidth: '40px', minHeight: '40px', marginLeft: "1%", marginTop: "2%" }}
                      >
                        <DeleteIcon />
                        
                      </Button>    
                      <Button onClick={(event) => handleActionDuplicate(event, row.index)} variant="contained" color="secondary" sx={{ ml: 1 }}>
                        <DuplicateIcon />
                      </Button>
                  
                      <Button disabled={loadingExport[1]} onClick={(event) => handleActionExport(event, row.index)} variant="contained" style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius: 10, marginLeft: "1%", marginTop: "2%" }}>
                        Exporter l'analyse
                        {loadingExport[1] && row.index === loadingExport[0] && (
                          <>
                            <CircularProgress />
                          </>
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal open={duplicateOpen} onClose={handleDuplicateCancel}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, borderRadius: 1 }}>
          <h2>Dupliquer la recette</h2>
          <TextField
            fullWidth
            label="Nouveau nom de la recette"
            value={newRecipeName}
            onChange={(e) => setNewRecipeName(e.target.value)}
          />
          <Button onClick={handleDuplicateConfirm} variant="contained" color="primary" sx={{ mt: 2 }} disabled={processing}>
            {processing ? <CircularProgress size={24} /> : "Confirmer"}
          </Button>
          <Button onClick={handleDuplicateCancel} variant="outlined" sx={{ mt: 2, ml: 1 }}>Annuler</Button>
        </Box>
      </Modal>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={() => setNotification({ ...notification, open: false })}>
  <Alert onClose={() => setNotification({ ...notification, open: false })} severity={notification.severity}>
    {notification.message}
  </Alert>
</Snackbar>
    </Box>
  );
}
