import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';
import { LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Leaf, ArrowRight, Lightbulb, UtensilsCrossed, Flower, Tractor } from 'lucide-react';
import TopRecipes from './TopRecipes';
import { useData } from '../../DataProvider';
import CircularProgress from '@mui/material/CircularProgress';


// Données de test

// Exemple de conseil détaillé
const tip = {
  id: 1,
  title: "Réduisez l’impact environnemental de vos menus en intégrant des alternatives végétales",
  description: "Par exemple, remplacez la viande hachée dans vos lasagnes par un mélange de lentilles corail et champignons finement hachés : une option riche en protéines, faible en gras, et tout aussi savoureuse. ",
  action: "https://foodprint.blob.core.windows.net/ressources/L'impact%20des%20diff%C3%A9rents%20r%C3%A9gimes%20alimentaire.pdf?sp=r&st=2024-06-27T12:52:44Z&se=2030-01-01T21:52:44Z&spr=https&sv=2022-11-02&sr=b&sig=BnIsVGNfqg%2Bva0uus73SVwDtgy5fzmuTbZ5Y71CdHvA%3D",
  impact: "👉 Environ -60% d'émissions par rapport aux plats contenant de la viande"
};

const ecoscoreColors = {
  "A+": "#1E8F4E",
  A: '#1E8F4E',
  B: '#2ECC71',
  C: '#F5C100',
  D: '#EF7E1A',
  E:  "#DE4523",
  F: " #BF3122"
};


const labelData = [
  { name: 'Bio', value: 35, color: '#4CAF50' },
  { name: 'Label Rouge', value: 25, color: '#F44336' },
  { name: 'AOP/AOC', value: 15, color: '#2196F3' },
  { name: 'IGP', value: 10, color: '#FF9800' },
  { name: 'HVE', value: 8, color: '#9C27B0' },
  { name: 'Conventionel', value: 7, color: '#607D8B' }
];

function generateEcoscoreData(stats) {
  const repartition = stats['Ecoscore_repartion']

  // Calcul de la somme totale des valeurs
  const total = Object.values(repartition).reduce((sum, value) => sum + value, 0);

  // Génération des données avec les proportions arrondies
  return Object.entries(repartition).map(([key, value]) => ({
    name: `Green-Score ${key}`,
    value: total > 0 ? +(value / total * 100).toFixed(0) : 0, // Calcul en pourcentage et arrondi
    color: ecoscoreColors[key],
  }));
}

const nutriscoreData = [
  { name: 'Nutriscore A', value: 10, color: '#1E8F4E' },
  { name: 'Nutriscore B', value: 30, color: '#2ECC71' },
  { name: 'Nutriscore C', value: 20, color: '#F5C100' },
  { name: 'Nutriscore D', value: 35, color: '#EF7E1A' },
  { name: 'Nutriscore E', value: 5, color: '#D93726' },
];

const formatCarbonData = (stats) => {
  const months = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
  const currentMonthIndex = new Date().getMonth();
  let last6 = stats.Last_6months_footprint
  last6.unshift(stats["Average_footprint"])
  console.log("last 6 footprint",last6)
  return last6.map((impact, index) => {
    let monthIndex = currentMonthIndex - index;
    if (monthIndex < 0) monthIndex += 12;
    return {
      month: months[monthIndex],
      impact: Math.round(impact)
    };
  }).reverse()
}

function updateLabelData(labelData, labelStats) {
  const updatedData = labelData.map(label => ({
    ...label,
    value: labelStats[label.name.toLowerCase()]
      ? +(labelStats[label.name.toLowerCase()] * 100).toFixed(2)
      : 0, // Mettre à 0 si le label n'existe pas dans stats
  }));

  // Calculer la somme des valeurs des autres labels
  const totalValue = updatedData.reduce((sum, label) => {
    if (label.name !== "Conventionel") {
      return sum + label.value;
    }
    return sum;
  }, 0);

  // Ajuster la valeur de "Conventionel" pour que le total soit 100
  return updatedData.map(label =>
    label.name === "Conventionel"
      ? { ...label, value: Math.max(0, +(100 - totalValue).toFixed(2)) }
      : label
  );
}

const getNoteAverage = notes => {
  const values = { 'A': 5, 'B': 4, 'C': 3, 'D': 2, 'E': 1 };
  const letters = { 5: 'A', 4: 'B', 3: 'C', 2: 'D', 1: 'E' };
  const avg = Math.round(notes.reduce((sum, note) => sum + values[note], 0) / notes.length);
  return letters[avg];
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={3} sx={{ padding: 2, backgroundColor: 'white' }}>
        <Typography variant="body2" color="textPrimary">{label}</Typography>
        {payload.map((entry, index) => (
          <Typography
            key={index}
            variant="body2"
            sx={{ color: entry.color || entry.payload.color }}
          >
            {`${entry.name}: ${entry.value}${entry.name === 'impact' ? ' kg CO₂e' : '%'}`}
          </Typography>
        ))}
      </Paper>
    );
  }
  return null;
};

const colorEvol = (title, change) => {
  if (title === "Impact Carbone Moyen") {
    return change <= 0 ? 'success.main' : 'error.main'
  } else {
    return change >= 0 ? 'success.main' : 'error.main'

  }
}

const StatCard = ({ title, value, change, icon: Icon, unit = '', foodprintscore }) => (

  <Card
    elevation={2}
    sx={{
      height: '100%',
      transition: 'all 0.3s',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: 6
      }
    }}
  >
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <Icon size={20} color="#059669" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Centre l'image
          alignItems: 'center',
          minHeight: '60px' // Assure une hauteur minimale consistante
        }}
      >
        {foodprintscore ? (
          <Box
            sx={{
              height: '40px',
              width: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              color: ecoscoreColors[foodprintscore],
              fontSize: '24px',
              fontWeight: 'bold',
              border: `2px solid ${ecoscoreColors[foodprintscore]}`,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            {foodprintscore}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
            <Typography variant="h4" component="div">
              {value}{unit}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: colorEvol(title, change),
                fontWeight: 500
              }}
            >
              {change >= 0 ? '+' : ''}{change}%
            </Typography>
          </Box>

        )}
      </Box>
    </CardContent>
  </Card>
);

function prepareStats(stats) {
  //TODO : cas où les stats sont vides
  if (!stats["Label_part"]) {
    return false;
  }
  // siqo = sum of all except bio
  stats["siqo"] = 0;
  //If no bio, set it to 0
  if (!stats["Label_part"]["bio"]) {
    stats["Label_part"]["bio"] = 0;
  }
  for (let key in stats["Label_part"]) {

    if (key !== "bio") {
      stats["siqo"] += parseInt(stats["Label_part"][key] * 100);
    }
  }
  stats["bio"] = parseInt(stats["Label_part"]["bio"] * 100);
  stats["conventionel"] = parseInt(100 - stats["bio"] - stats["siqo"]);

  return stats;
}

const RestaurantDashboard = () => {
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();
  //Get current month in french
  const date = new Date();
  const options = { month: 'long' };
  let month = new Intl.DateTimeFormat('fr-FR', options).format(date);
  //Upper case first char
  month = month.charAt(0).toUpperCase() + month.slice(1);
  //Get current year
  const year = date.getFullYear();
  let stats = prepareStats(prod_stats);
  console.log("Stats", stats)
  const handleClick = () => {
    console.log('Clicked for more details');
    // Gérer l'action ici
  };
  if (isLoading) {
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if (Object.keys(prod_stats).length === 0) {
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', verticalAlign: 'center' }} >
          <Alert severity="info" sx={{ verticalAlign: 'center' }}>
            <AlertTitle>Information</AlertTitle>
            Vous n'avez pas encore de données à afficher pour ce mois ci. <br />
            Veuillez saisir vos menus.
          </Alert>
        </Box>
      </div>
    )
  }
  else if (prod_stats["Label_part"]) {
    let last6 = formatCarbonData(stats)
    console.log("last6", last6)
    let local_evol = 0
    if (stats['local_part_list'][1]) {
      local_evol = (((stats['local_part_list'][0] - stats['local_part_list'][1]) / stats['local_part_list'][1]) * 100).toFixed(0)
    }
    return (
      <Box sx={{ p: 3, bgcolor: '#f5f5f5', minHeight: '100vh', marginLeft: "15%" }}>
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <img src="logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>Tableau de bord - {month} {year}</h1>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 3,
            autoRows: 'minmax(100px, auto)'
          }}
        >

          <StatCard
            title="Score FoodPrint"
            foodprintscore={getNoteAverage(stats['Ecoscore_list'])}
            change={5}
            icon={UtensilsCrossed}
          />
          <StatCard
            title="Impact Carbone Moyen"
            value={(stats['Average_footprint'] / 1000).toFixed(1)}
            unit=" kg CO₂e"
            change={(((last6[last6.length -1]["impact"] - last6[last6.length -2]["impact"]) / last6[last6.length -2]["impact"]) * 100).toFixed(0)}
            icon={Leaf}
          />
          <StatCard
            title="Part de local"
            value={stats["local_part_list"] * 100}
            unit="%"
            change={local_evol}
            icon={Tractor}
          />
          <StatCard
            title="Part du Bio"
            value={(stats["Label_part"]['bio'] * 100).toFixed(0)}
            unit="%"
            change={0}
            icon={Flower}
          />

          <Card
            elevation={2}
            sx={{
              maxWidth: 400,
              gridColumn: 'span 1',
              gridRow: 'span 2',
              borderRadius: 3,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-4px)',
              },
            }}
          >
            <CardContent sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              pb: '16px !important' // Supprime le padding par défaut du CardContent
            }}>
              {/* Icône et titre */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  sx={{
                    backgroundColor: 'primary.light',
                    borderRadius: '50%',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Lightbulb size={24} color="#1976d2" />
                </Box>
                <Typography
                  variant="h6"
                  component="h2"
                  color="primary.main"
                  fontWeight="bold"
                >
                  Conseil du jour
                </Typography>
              </Box>

              {/* Contenu principal */}
              <Typography variant="h5" component="h3">
                {tip.title}
              </Typography>

              <Typography variant="body1" color="text.secondary">
                {tip.description}
              </Typography>

              {/* Impact */}
              <Box
                sx={{
                  backgroundColor: 'success.light',
                  borderRadius: 2,
                  p: 2
                }}
              >
                <Typography variant="body2" color="success.dark">
                  {tip.impact}
                </Typography>
              </Box>

              {/* Bouton */}
              <Button
                variant="contained"
                fullWidth
                endIcon={<ArrowRight />}
                href={tip.action}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  py: 1.5
                }}
              >
                Passez à l'action
              </Button>
            </CardContent>
          </Card>

          <Card
            elevation={2}
            sx={{
              gridColumn: 'span 2',
              gridRow: 'span 1',
              height: '300px' // Augmentation de la hauteur
            }}
          >
            <CardContent
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px !important' // Supprime le padding par défaut du dernier élément
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Impact Carbone par Repas
              </Typography>
              <Box sx={{ flex: 1, minHeight: 0, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="90%">
                  <LineChart
                    data={last6}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" tick={{ fontSize: 12 }} />
                    <YAxis unit=" kg CO₂e" tick={{ fontSize: 10 }} padding={{ top: 20, bottom: 0 }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="impact"
                      stroke="#059669"
                      strokeWidth={2}
                      dot={{ fill: '#059669', strokeWidth: 2 }}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>

          <Card
            elevation={2}
            sx={{
              gridColumn: 'span 1',
              gridRow: 'span 1',
              height: '300px'
            }}
          >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Labels
              </Typography>
              <Box sx={{ flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart margin={{ left: 10, right: 10 }}> {/* Ajout des marges */}
                    <Pie
                      data={updateLabelData(labelData, stats.Label_part)}
                      cx="40%" // Ajusté de 35% à 40%
                      cy="50%"
                      innerRadius={40}
                      outerRadius={60}
                      paddingAngle={2}
                      dataKey="value"
                    >
                      {updateLabelData(labelData, stats.Label_part).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      iconType="circle"
                      wrapperStyle={{
                        paddingLeft: '10px',
                        fontSize: '12px'
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>

          <Card
            elevation={2}
            sx={{
              gridColumn: 'span 1',
              gridRow: 'span 1',
              height: '300px'
            }}
          >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Green-Score de vos recettes
              </Typography>
              <Box sx={{ flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart margin={{ left: 10, right: 10 }}> {/* Ajout des marges */}
                    <Pie
                      data={generateEcoscoreData(stats)}
                      cx="40%" // Ajusté de 35% à 40%
                      cy="50%"
                      innerRadius={40}
                      outerRadius={60}
                      paddingAngle={2}
                      dataKey="value"
                    >
                      {generateEcoscoreData(stats).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      iconType="circle"
                      wrapperStyle={{
                        paddingLeft: '10px',
                        fontSize: '12px'
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>

          <Card
            elevation={2}
            sx={{
              gridColumn: 'span 2',
              gridRow: 'span 1',
              height: '300px'
            }}
          >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" >
                Vos recettes les plus durables
              </Typography>
              <Box sx={{ flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="90%" height="100%">
                  <TopRecipes />
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  }
};

export default RestaurantDashboard;