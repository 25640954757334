import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { BarChart } from '@mui/x-charts/BarChart'; 
import { Box } from '@mui/material';

export default function RatiosChartsIndicators({stats}) {
  const [ratio, setRatio] = React.useState('nutriscore');
  const valueFormatter = (value) => `${value}%`;
  const [data, setData] = React.useState(Object.values(stats["Nutriscore_repartion"]));
  const handleChange = (event, newRatio) => {
    // temporaire pour tester sans vraie données :
    if (newRatio !== null) { // Check if a new value is selected
      if(newRatio==="nutriscore"){
        setData(Object.values(stats["Nutriscore_repartion"]));
      }else if(newRatio==="ecoscore"){
        setData(Object.values(stats["Ecoscore_repartion"]));
      }
      setRatio(newRatio);
    }

  };
  const getColorForXValue = (index) => {
    switch (index) {
      case 0:
        return '#4CAF50';
      case 1:
        return '#8BC34A';
      case 2:
        return '#FFEB3B';
      case 3:
        return '#FF9800';
      case 4:
        return '#F44336';
      default:
        return '#000000';
    }

  };

  return (
    <Box sx={{height:"65%", width:"100%"}}>
      <ToggleButtonGroup
        color="primary"
        value={ratio}
        exclusive
        onChange={handleChange}
        aria-label="Ratio"
        style={{marginBottom:15}}
      >
        <ToggleButton value="nutriscore">Nutriscore</ToggleButton>
        <ToggleButton value="ecoscore">Green-Score</ToggleButton>
        </ToggleButtonGroup>
        <BarChart
            xAxis={[{ scaleType: 'band', data: ['A', 'B', 'C', 'D', 'E'] }]}
            series={[{ data }]}
            seriesColors={(series, index) => getColorForXValue(index)}        />
    </Box>
    
  );
}